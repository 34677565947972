<template>
  <div>
    <!-- <head-layout  :head-title="'数据汇聚配置'"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">
    </head-layout> -->
    <el-container class="new-sino-box" style="padding: 0;">
      <el-header class="new-sino-header search-form" style="display: flex;justify-content: space-between">

        <div >
          <el-input :placeholder="$t('cip.dc.DataAggregation.field.AggregationCoding')" v-model="queryList.aggCode" class="new-sino-input" size="mini" style="width: 200px;"/>
          <el-input :placeholder="$t('cip.dc.DataAggregation.field.Convergencetime')" v-model="queryList.aggTime" class="new-sino-input" size="mini" style="width: 200px;" />
          <el-input :placeholder="$t('cip.dc.DataAggregation.field.Convergencedimension')" v-model="queryList.dimension" class="new-sino-input" size="mini" style="width: 200px;" />
          <el-input :placeholder="$t('cip.dc.DataAggregation.field.Aggregationsourcetable')" v-model="queryList.sourceTable" class="new-sino-input" size="mini" style="width: 200px;" />
          <el-button-group style="margin-left: 10px;">
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
          </el-button-group>
        </div>
       <div>
        <el-button-group>
          <el-button size="mini"  @click="addFn">{{ $t('DataAggregation.btn.add') }}</el-button>
         <el-button size="mini"  @click="deleteFn">{{ $t('DataAggregation.btn.remove') }}</el-button>
        </el-button-group>

       </div>
      </el-header>

      <grid-layout
            ref="gridLayout"
            :data-total="total"
            @page-size-change="handleSizeChange"
            @page-current-change="handleCurrentChange"
            @page-refresh-change="onLoad"
            :page="page"
            @gird-handle-select-click="selectionChange"
            :tableOptions="tableOptions"
            :tableData="tableData"
            :tableLoading="tableLoading"
            :gridRowBtn="gridRowBtn"
            @grid-see="SeeData"
            @grid-edit="handleEdit"
            @grid-romve="rowDel"
          >
          </grid-layout>


      <!-- <el-main class="new-sino-main">
        <el-table
          height="60vh"
          size="small"
          v-loading="loading"
          element-loading-text="loading"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          border
          :data="tableData"
          @selection-change="handleSelectionChange"
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="aggCode"
            :label="$t('cip.dc.DataAggregation.field.AggregationCoding')"
            width="180">
          </el-table-column>
          <el-table-column
            align="center"
            prop="aggTime"
            :label="$t('cip.dc.DataAggregation.field.Convergencetime')"
            width="180">
          </el-table-column>
          <el-table-column
            align="center"
            prop="dimension"
            :label="$t('cip.dc.DataAggregation.field.Convergencedimension')">
          </el-table-column>
          <el-table-column
            align="center"
            prop="sourceTable"
            :label="$t('cip.dc.DataAggregation.field.Aggregationsourcetable')">
          </el-table-column>
          <el-table-column
            align="center"
            prop="taskGroup"
            :label="$t('cip.dc.DataAggregation.field.Taskgrouping')">
          </el-table-column>
          <el-table-column
            align="center"
            prop="taskExecuteOrder"
            :label="$t('cip.dc.DataAggregation.field.Executionorder')">
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            :label="$t('cip.dc.DataAggregation.field.operation')">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)">{{ $t('DataAggregation.btn.edit') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="float:right;height: 60px;">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="queryList.query.current"
            :page-size="queryList.query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-main> -->
    </el-container>

  </div>
</template>

<script>
import {
  dataConvergeList,
  dataConvergeListUp,
  dataConvergeListDel, itIndel
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import eventBus from "../../../../util/eventBus"
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 150,
        column: [
          {
            label: this.$t('cip.dc.DataAggregation.field.AggregationCoding'),
            prop: "aggCode",
            cell: true,
          },
          {
            label: this.$t('cip.dc.DataAggregation.field.Convergencetime'),
            prop: "aggTime",
            cell: true,
          },
          {
            label: this.$t('cip.dc.DataAggregation.field.Convergencedimension'),
            prop: "dimension",
            cell: true,
          },


          {
            label: this.$t('cip.dc.DataAggregation.field.Aggregationsourcetable'),
            prop: "sourceTable",
            cell: true,
          },
          {
            label: this.$t('cip.dc.DataAggregation.field.Taskgrouping'),
            prop: "taskGroup",
            cell: true,
          },
          {
            label: this.$t('cip.dc.DataAggregation.field.Executionorder'),
            prop: "taskExecuteOrder",
            cell: true,
          },



        ],

      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "grid-edit",
          remark: 'edit',
          type: "text",
          icon: ""
        },

        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        },


      ],
      tableLoading:false,
      multipleSelection:[],
      tableData: [],
      total:0,
      queryList:{
        query:{
          current:1,
          size:20,
        },
        aggCode: "",
        aggTime: "",
        dimension: "",
        sourceTable: "",
        taskExecuteOrder: ""
      },
      loading:false,
      headBtnOptions: [{
        label: this.$t(`cip.cmn.btn.addBtn`),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      dataConvergeList(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.records
        this.total = obj.total
        this.page.currentPage=this.queryList.query.page
        this.page.pageSize = this.queryList.query.size
      });
    },
    addFn(){
      this.$router.push({
        path: '/dataConvergence/configAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(item){
      this.$router.push({
        path: '/dataConvergence/configAdd',
        query: {
          type: 'edit',
          id:item.id
        }
      })
      localStorage.setItem('conEditObj',JSON.stringify(item))
    },
    rowDel(e){
      let ids = []
      ids[0] = e.id

      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataConvergeListDel({ids:ids}).then((res)=>{
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return Number(item.id)
      })
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataConvergeListDel({ids:ids}).then((res)=>{
          this.$message.success('删除成功');
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    saveFn(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.id){
            dataConvergeListUp(this.form).then(()=>{
              this.$message.success('修改成功！');
              this.dialogFormVisible = false;
              this.onLoad()
            })
          }else{
            dataConvergeListUp(this.form).then(()=>{
              this.$message.success('新增成功！');
              this.dialogFormVisible = false;
              this.onLoad()
            })
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    czFn(){
      this.queryList = {
        query:{
          current:1,
          size:10,
        },
        aggCode: "",
        aggTime: "",
        dimension: "",
        sourceTable: "",
        taskExecuteOrder: ""
      }
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.page.pageSize = val.pageSize
      this.queryList.query.size = val.pageSize;
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.query.page = val.currentPage;
      this.onLoad()
    },
  },
  created() {
    this.onLoad()
  },
  mounted() {
    eventBus.$on("huiju2",res=>{
      this.onLoad()
    })
  },
};
</script>
<style lang="less" scoped>
.search-form::v-deep{
   background: #FFFFFF;
   padding: 14px 12px;
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
.new-sino-header{
  height:56px !important;
}
::v-deep .search-form .el-input{
  margin-right: 10px;
}
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
</style>
